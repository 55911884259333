import React from 'react';

export const LandingImage = () => {
    return (
        <div>
            <a href="https://app.bsi.ge">
                <img src="/img/logo.png" className="App-logo" alt="logo" />
            </a>
        </div>
    );
};
